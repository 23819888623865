<!-- @format -->
<script lang="ts">
  import { getStoresContext, type StoresKey } from "~/stores";
  import { getStores } from "../utils";
  import type { ManageAPI } from "~/manage-api/types";
  import { ANY_DAY, ANY_STORE, ANY_TAG, filter_by_category, filter_by_day, filter_by_store } from "../store";
  
  export let request_store_key: StoresKey;

  const stores_context = getStoresContext(request_store_key);
  const { T, offers_ext } = stores_context;

  $: ({ items } = $offers_ext);
  function action(id: number) {
    filter_by_store.set(id);
    value = storesSuggest.find((store) => store.id == id)?.name || "";
    storesSuggest = [];
  }

  let value = "";
  let storesSuggest: Pick<ManageAPI.Store, 'id' | 'name'>[] = [];
  $: t = $T;

  function filterByStore (e: Event) {
    const input = e.currentTarget instanceof HTMLInputElement ? e.currentTarget.value : '';
    if (input) {
      const stores = items && getStores(items);
      $filter_by_day = ANY_DAY;
      $filter_by_category = ANY_TAG;
      storesSuggest = stores.filter(
        (store) =>
          store.name.toLowerCase().indexOf(input.toLowerCase()) > -1,
      );
    } else {
      $filter_by_store = ANY_STORE;
      storesSuggest = [];
    }
  }

</script>

<div class="search-wishlist__container mb-4">
  {#if items && items.length}
    <div class="search__container">
      <div class="input-group p-1">
        <input
          on:input={filterByStore}
          bind:value
          type="text"
          placeholder={t`offers.searchBox`}
          class="search-box form-control bg-none border-0" />
        <div class="input-group-append border-0">
          <button id="button-addon3" type="button" class="btn btn-link"
            ><i class="fa fa-search"></i></button>
        </div>
      </div>
      {#if storesSuggest.length}
        <div class="search-dropdown dropdown-menu show">
          {#each storesSuggest as store}
            <button
              class="stores__suggestion dropdown-item"
              on:click={() => {
                action(store.id);
              }}>
              {store.name}
            </button>
          {/each}
        </div>
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  button {
    border: none;
  }
  .input-group-append i {
    color: #000;
  }
  .search__container {
    position: relative;
    margin: 0 auto;
    width: 70%;
    max-width: 700px;
    padding: 0 5px;

    .input-group {
      background: #fff;
      border: 1px solid #787878;
    }

    .search-box {
      background: #fff;
      height: 50px;
    }

    .form-control:focus {
      border: none;
      box-shadow: none;
    }

    button {
      background: #fff;
    }

    .dropdown-menu {
      top: 57px;
      left: 5px;
      max-height: 250px;
      overflow-y: scroll;
    }

    .dropdown-item {
      cursor: pointer;
    }

    .dropdown-item:hover {
      background-color: #c6c6c6;
      color: #fff;
    }
  }

  .search-wishlist__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  @media screen and (max-width: 576px) {
    .search__container {
      width: 100%;
      margin-bottom: 20px;
    }
  }
</style>
